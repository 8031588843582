import { API } from '@/services/api.js'

export default {
  login(payload) {
    return API.post('v1/triple-a-user/user/login', payload)
  },
  set_password(payload) {
    return API.post('v1/triple-a-user/set-password', payload)
  },
  forgot_userid(payload) {
    return API.put('v1/triple-a-user/forgot-userid', payload)
  },
  send_password_reset_request(payload) {
    return API.post('v1/triple-a-user/forgot-password', payload)
  },
  verify_otp(payload) {
    return API.put('v1/triple-a-user/verify-otp', payload)
  },
  reset_password(payload) {
    return API.put('v1/triple-a-user/reset-password', payload)
  },
  change_password(payload) {
    return API.post('v1/triple-a-user/change-password', payload)
  },
  reset_admin_password(payload) {
    return API.put('v1/triple-a-user/reset-admin-password', payload)
  }
}