<template>
  <v-app id="app">
    <router-view />
    <snackbar ref="snackbar" />
  </v-app>
</template>

<script>
import snackbar from '@/components/Snackbar.component'
export default {
  name: 'App',
  components: {
    snackbar,
  },
  data: () => ({
    //
  }),
  mounted() {
    this.$root.snackbar = this.$refs.snackbar
  },
}
</script>

<style lang="scss">
</style>
