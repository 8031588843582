<template>
  <v-snackbar
    :color="color"
    :timeout="timer"
    v-model="showSnackbar"
    bottom
    left
  >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="showSnackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'snackbar',
  data: () => ({
    showSnackbar: false,
    message: '',
    color: 'success',
    icon: 'mdi-check',
    timer: 2000,
  }),
  methods: {
    getMessage(data) {
      if (typeof data !== 'undefined') {
        if (typeof data === 'string') {
          return data
        } else if (Array.isArray(data)) {
          return data[0]
        }
      } else {
        return 'Unknown error occured'
      }
    },
    show(data) {
      this.message = this.getMessage(data.message)
      this.color = data.color || 'success'
      this.timer = data.timer || 2000
      this.icon = data.icon || 'mdi-check'
      this.showSnackbar = true
    },
  },
}
</script>