<template>
  <div class="login-grid">
    <div
      class="login-form"
      v-bind:class="{ 'set-password': loginPage === 'reset-password' }"
    >
      <img class="logo" src="@/assets/logo.png" alt="Orbis" />
      <!-- MAIN LOGIN -->
      <div v-if="loginPage === 'login'">
        <h2>Login</h2>
        <p class="subheader">Add your credentials to continue.</p>
        <v-form ref="loginForm">
          <v-text-field
            label="User ID"
            v-model="loginForm.user_id"
            :rules="userIdRules"
            outlined
          ></v-text-field>
          <v-text-field
            label="Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            v-model="loginForm.password"
            :rules="passwordRules"
            outlined
          ></v-text-field>
          <div class="forgot-password-container justify-space-between mb-3">
            <!-- <v-checkbox
              v-model="login.rememberme"
              color="var(--primary)"
              dense
              label="Remember Me"
              class="ma-0 pa-0"
            > -->
            <!-- <template v-slot:label>
                <span class="subheader remember-me">Remember Me</span>
              </template> -->
            <!-- </v-checkbox> -->
            <a @click="loginPage = 'forgot-userid'" class="forgot-password"
              >Forgot UserId</a
            >
            <a @click="loginPage = 'forgot-password'" class="forgot-password"
              >Forgot Password</a
            >
          </div>
          <v-btn
            color="var(--primary)"
            dark
            :loading="loading"
            class="primary-btn"
            @click="login"
            >Login
            <v-icon dark class="ml-2" small> mdi-arrow-right </v-icon>
          </v-btn>
        </v-form>
      </div>
      <!-- 2FA  -->
      <!-- <div v-else-if="loginPage === '2fa'">
        <a
          color="var(--subtitle)"
          @click="loginPage = 'login'"
          class="mb-5 back-btn"
        >
          <v-icon class="mr-2" small> mdi-chevron-left </v-icon>Back
        </a>
        <h2>Verify your email</h2>
        <p class="subheader">
          Please enter the 6-digit verification code sent to your email.
        </p>
        <v-otp-input type="number" v-model="otp" length="6"></v-otp-input>
        <v-btn
          :disabled="otp.length <= 5"
          color="var(--primary)"
          :dark="otp.length > 5"
          class="primary-btn"
          @click="verifyOtp"
          >Verify
          <v-icon dark class="ml-2" small> mdi-arrow-right </v-icon>
        </v-btn>
      </div> -->

      <!-- FORGOT USERID -->
      <div v-else-if="loginPage === 'forgot-userid'">
        <a
          color="var(--subtitle)"
          @click="loginPage = 'login'"
          class="mb-5 back-btn d-flex align-items-center"
        >
          <v-icon small> mdi-chevron-left </v-icon> Back
        </a>
        <h2>Forgot User ID</h2>

        <p class="subheader">
          Please enter your registered email. We will send your user ID in your
          email.
        </p>
        <!-- <v-otp-input type="number" v-model="otp" length="6"></v-otp-input> -->
        <v-text-field
          :rules="emailRules"
          v-model="forgotUserIdEmail"
          label="Email"
          class="mb-2"
          outlined
        ></v-text-field>
        <v-btn
          :disabled="forgotUserIdEmail === ''"
          color="var(--primary)"
          :dark="forgotUserIdEmail !== ''"
          class="primary-btn"
          @click="forgotUserIdRequest"
          :loading="passwordResetRequestLoading"
        >
          Send request
          <v-icon dark class="ml-2" small> mdi-arrow-right </v-icon>
        </v-btn>
      </div>

      <!-- FORGOT PASSWORD -->
      <div v-else-if="loginPage === 'forgot-password'">
        <a
          color="var(--subtitle)"
          @click="loginPage = 'login'"
          class="mb-5 back-btn d-flex align-items-center"
        >
          <v-icon small> mdi-chevron-left </v-icon> Back
        </a>
        <h2>Forgot password</h2>

        <p class="subheader">
          To reset your password please enter your registered email. We will
          send a reset OTP in your email.
        </p>
        <!-- <v-otp-input type="number" v-model="otp" length="6"></v-otp-input> -->
        <v-text-field
          :rules="emailRules"
          v-model="forgotPasswordEmail"
          label="Email"
          class="mb-2"
          outlined
        ></v-text-field>
        <v-btn
          :disabled="forgotPasswordEmail === ''"
          color="var(--primary)"
          :dark="forgotPasswordEmail !== ''"
          class="primary-btn"
          @click="sendPasswordResetRequest"
          :loading="passwordResetRequestLoading"
        >
          Send Otp request
          <v-icon dark class="ml-2" small> mdi-arrow-right </v-icon>
        </v-btn>
      </div>

      <!-- VERIFY OTP FOR RESET PASSWORD -->
      <div v-else-if="loginPage === 'verify-otp'">
        <a
          color="var(--subtitle)"
          @click="loginPage = 'login'"
          class="mb-3 back-btn d-flex align-items-center"
        >
          <v-icon small> mdi-chevron-left </v-icon>Back
        </a>
        <h2>Verify OTP</h2>
        <p class="subheader pb-0">
          Please enter the 6-digit verification code sent to
          {{ forgotPasswordEmail }} .
        </p>
        <v-form ref="verifyOtpForm">
          <v-otp-input
            v-model="verifyOtpForm.otp"
            :rules="otpRules"
            length="6"
            @finish="otpUpdated"
          ></v-otp-input>
          <v-btn
            color="var(--primary)"
            class="primary-btn mt-2"
            @click="verifyOtp"
            :loading="loading"
            dark
            >Verify Otp
            <v-icon dark class="ml-2" small> mdi-arrow-right </v-icon>
          </v-btn>
        </v-form>
        <p class="subheader undertext">
          Didn't receive the OTP?
          <a @click="resendOtp">Resend</a>
        </p>
      </div>

      <!-- RESET PASSWORD -->
      <div v-else-if="loginPage === 'reset-password'">
        <a
          color="var(--subtitle)"
          @click="loginPage = 'login'"
          class="mb-3 back-btn d-flex align-items-center"
        >
          <v-icon small> mdi-chevron-left </v-icon>Back
        </a>
        <h2>Reset Password</h2>
        <p class="subheader pb-0">Please enter a new password</p>
        <v-form ref="resetPasswordForm">
          <v-text-field
            label="New Password"
            :rules="passwordRules"
            v-model="resetPasswordForm.newPassword"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showNewPassword ? 'text' : 'password'"
            @click:append="showNewPassword = !showNewPassword"
            outlined
          ></v-text-field>
          <v-text-field
            label="Confirm Password"
            :rules="confirmResetPasswordRules"
            v-model="resetPasswordForm.confirmPassword"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            outlined
          ></v-text-field>
          <v-btn
            color="var(--primary)"
            class="primary-btn mt-2"
            @click="resetPassword"
            :loading="loading"
            dark
            >Change Password
            <v-icon dark class="ml-2" small> mdi-arrow-right </v-icon>
          </v-btn>
        </v-form>
      </div>

      <!-- SET PASSWORD FOR FIRST TIME LOGIN -->
      <div v-else-if="loginPage === 'set-password'">
        <a
          color="var(--subtitle)"
          @click="loginPage = 'login'"
          class="mb-5 back-btn"
        >
          <v-icon class="mr-2" small> mdi-chevron-left </v-icon>Back
        </a>
        <h2>Set Password</h2>
        <p class="subheader">Set a new secure password.</p>

        <v-form ref="setPasswordForm">
          <v-text-field
            label="New Password"
            v-model="setPasswordForm.new_password"
            :rules="newPasswordRules"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showNewPassword ? 'text' : 'password'"
            @click:append="showNewPassword = !showNewPassword"
            outlined
          ></v-text-field>
          <v-text-field
            label="Confirm Password"
            v-model="setPasswordForm.confirm_password"
            :rules="confirmPasswordRules"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            outlined
          ></v-text-field>
          <v-btn
            color="var(--primary)"
            dark
            class="primary-btn"
            @click="setPassword"
            :loading="loading"
            >Set Password
            <v-icon dark class="ml-2" small> mdi-arrow-right </v-icon>
          </v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      loading: false,
      passwordResetRequestLoading: false,
      requestEmail: '',
      submittedEmail: false,
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      showLogin: false,
      show2FA: false,
      showForgotPassword: false,
      loginPage: 'login',
      verifyOtpForm: {
        otp: '',
      },
      loginForm: {
        user_id: '',
        password: '',
      },
      forgotPasswordEmail: '',
      forgotUserIdEmail: '',
      resetPasswordOtp: '',

      userIdRules: [(v) => !!v || 'User ID is required '],
      emailRules: [(v) => !!v || 'Email is required '],
      otpRules: [(v) => !!v || 'Otp code is required '],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) =>
          (v && v.length <= 64) || 'Password must be less than 64 characters',
      ],
      setPasswordForm: {
        new_password: '',
        confirm_password: '',
      },
      resetPasswordForm: {
        newPassword: '',
        confirmPassword: '',
      },
      newPasswordRules: [
        (v) => !!v || 'Password is required',
        (v) =>
          (v && v.length >= 8 && v.length <= 64) ||
          'Password must be between 8-64 characters long',
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).*$/gm.test(v) ||
          'New password must contain 1 special character(@#$%^&+=!), 1 uppercase and 1 lowercase character',
      ],
      confirmPasswordRules: [
        (v) => !!v || 'Password is required',
        (v) =>
          (v && v.length >= 8 && v.length <= 64) ||
          'Password must be between 8-64 characters long',
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).*$/gm.test(v) ||
          'New password must contain 1 special character(@#$%^&+=!), 1 uppercase and 1 lowercase character',
        () =>
          this.setPasswordForm.new_password ===
            this.setPasswordForm.confirm_password || 'Passwords do not match',
      ],
      confirmResetPasswordRules: [
        (v) => !!v || 'Password is required',
        (v) =>
          (v && v.length >= 8 && v.length <= 64) ||
          'Password must be between 8-64 characters long',
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).*$/gm.test(v) ||
          'New password must contain 1 special character(@#$%^&+=!), 1 uppercase and 1 lowercase character',
        () =>
          this.resetPasswordForm.newPassword ===
            this.resetPasswordForm.confirmPassword || 'Passwords do not match',
      ],
    }
  },
  methods: {
    submitEmail() {
      this.submittedEmail = true
    },
    async login() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true
        try {
          let response = await this.$store.dispatch('login', this.loginForm)
          if (response.data.isPasswordSet) {
            this.$store.commit('authenticate', true)
            this.$router.push('/home')
            this.$root.snackbar.show({
              message: 'Successfully logged in.',
              color: 'success',
            })
          } else {
            this.loginPage = 'set-password'
            // avoid triggering the validation at first
            setTimeout(() => {
              this.$refs.setPasswordForm.reset()
            })
            this.$root.snackbar.show({
              message: 'Please set new password.',
              color: 'success',
            })
          }
        } catch (error) {
          let errorMsg = error.response
            ? error.response.data.message
            : 'Network error'
          this.$root.snackbar.show({
            message: errorMsg,
            color: 'error',
          })
        } finally {
          this.loading = false
        }
      } else {
        // this.$root.snackbar.show({
        //   message: 'Required fields are missing.',
        //   color: 'error',
        // })
      }
    },
    async setPassword() {
      if (this.$refs.setPasswordForm.validate()) {
        this.loading = true
        try {
          await this.$store.dispatch('set_password', this.setPasswordForm)
          this.$root.snackbar.show({
            message: 'Password set successfully.',
            color: 'success',
          })
          this.$store.commit('authenticate', true)
          this.$router.push('/home')
        } catch (error) {
          let errorMsg = error.response
            ? error.response.data.message
            : 'Network error'
          this.$root.snackbar.show({
            message: errorMsg,
            color: 'error',
          })
        } finally {
          this.loading = false
        }
      } else {
        // this.$root.snackbar.show({
        //   message: 'Required fields are missing.',
        //   color: 'error',
        // })
      }
    },
    async forgotUserIdRequest() {
      this.passwordResetRequestLoading = true
      let payload = {}
      payload.email = this.forgotUserIdEmail
      try {
        await this.$store.dispatch('forgot_userid', payload)
        this.loginPage = 'login'
        this.$root.snackbar.show({
          message: 'Operation successful. Please check your email for user Id',
          color: 'success',
        })
      } catch (error) {
        let errorMsg = error.response
          ? error.response.data.message
          : 'Network error'
        this.$root.snackbar.show({
          message: errorMsg,
          color: 'error',
        })
      } finally {
        this.passwordResetRequestLoading = false
      }
    },
    async sendPasswordResetRequest() {
      if (this.forgotPasswordEmail) {
        this.passwordResetRequestLoading = true
        try {
          let payload = {}
          payload.email = this.forgotPasswordEmail
          await this.$store.dispatch('send_password_reset_request', payload)
          this.loginPage = 'verify-otp'
        } catch (error) {
          let errorMsg = error.response
            ? error.response.data.message
            : 'Network error'
          this.$root.snackbar.show({
            message: errorMsg,
            color: 'error',
          })
        } finally {
          this.passwordResetRequestLoading = false
        }
      } else {
        // this.$root.snackbar.show({
        //   message: 'Email is required.',
        //   color: 'error',
        // })
      }
    },
    async verifyOtp() {
      if (this.$refs.verifyOtpForm.validate()) {
        this.loading = true
        try {
          let payload = {}
          payload.otpCode = this.verifyOtpForm.otp
          await this.$store.dispatch('verify_otp', payload)
          this.$root.snackbar.show({
            message: 'Otp validated',
            color: 'success',
          })
          this.loginPage = 'reset-password'
        } catch (error) {
          let errorMsg = error.response
            ? error.response.data.message
            : 'Network error'
          this.$root.snackbar.show({
            message: errorMsg,
            color: 'error',
          })
        } finally {
          this.loading = false
        }
      } else {
        this.$root.snackbar.show({
          message: 'Otp is missing',
          color: 'error',
        })
      }
    },
    otpUpdated(otp) {
      // to update on copy paste in otp field
      this.verifyOtpForm.otp = otp
    },
    async resetPassword() {
      if (this.$refs.resetPasswordForm.validate()) {
        this.loading = true
        try {
          let payload = {
            otpCode: this.verifyOtpForm.otp,
            email: this.forgotPasswordEmail,
            password : this.resetPasswordForm.confirmPassword
          }
          await this.$store.dispatch('reset_password', payload)
          this.$root.snackbar.show({
            message: 'Password reset successfully',
            color: 'success',
          })
          this.loginPage = 'login'
        } catch (error) {
          let errorMsg = error.response
            ? error.response.data.message
            : 'Network error'
          this.$root.snackbar.show({
            message: errorMsg,
            color: 'error',
          })
        } finally {
          this.loading = false
        }
      } else {
        // this.$root.snackbar.show({
        //   message: 'Required fields are missing',
        //   color: 'error',
        // })
      }
    },
    resendOtp() {
      this.sendPasswordResetRequest()
    },
  },
}
</script>

<style lang="scss" scoped>
.login-grid {
  display: flex;
  // grid-template-columns: 40% 60%;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  width: 100%;
}

.login-form {
  padding: 10% 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
  @media (min-width: 768px) {
    padding: 10% 10%;
    width: 70%;
    display: block;
  }
  @media (min-width: 1024px) {
    width: 60%;
    display: block;
  }
  @media (min-width: 1440px) {
    width: 50%;
    display: block;
  }
}

.set-password {
  padding: 5% 10%;
}

.v-label {
  font-size: 14px;
}

.logo {
  width: 200px;
  height: auto;
  margin-bottom: 10%;
  margin-left: 1%;
}

h2 {
  color: #000;
  margin-bottom: 1rem;
}

.subheader {
  color: var(--subtitle);
  font-size: 14px;
}

.undertext {
  margin-top: 0.5rem;
  text-align: center;

  a {
    font-weight: bold;
  }
}

.primary-btn {
  width: 100%;
}

.forgot-password-container {
  display: flex;
  justify-content: space-between;

  .v-input--selection-controls .v-input__slot > .v-label {
    display: inline-block;
  }

  label.v-label.theme--light {
    display: inline-block;
  }
}

.forgot-password {
  color: var(--subtitle);
}

.back-btn {
  color: var(--subtitle);
  font-size: 13px;
  margin-bottom: 2rem;
  display: inline-block;
}

.login-banner {
  // background: url(@/assets/login-banner.png);
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: auto;
  }
}
</style>
