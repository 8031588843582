import { API_USER, API_ACCOUNT } from '@/services/api.js'

export default {
  create_user(payload) {
    return API_USER.post('v1/triple-a-admin/create-user', payload)
  },
  create_admin(payload) {
    return API_USER.post('v1/triple-a-admin/create-admin', payload)
  },
  get_admin_list() {
    return API_USER.get('v1/triple-a-admin/get-admins-list')
  },
  get_user_list() {
    return API_USER.get('v1/triple-a-admin/get-users-list')
  },
  edit_user(payload) {
    return API_USER.post('v1/triple-a-admin/edit-user', payload)
  },
  edit_admin(payload) {
    return API_USER.post('v1/triple-a-admin/edit-admin', payload)
  },
  activate_user(payload) {
    return API_USER.post('v1/triple-a-admin/activate-user', payload)
  },
  deactivate_user(payload) {
    return API_USER.post('v1/triple-a-admin/deactivate-user', payload)
  },
  activate_admin(payload) {
    return API_USER.post('v1/triple-a-admin/activate-admin', payload)
  },
  deactivate_admin(payload) {
    return API_USER.post('v1/triple-a-admin/deactivate-admin', payload)
  },
  upload_single_doc(payload) {
    return API_USER.post('v1/triple-a-admin/single-doc-upload', payload)
  },
  upload_docs(payload) {
    return API_USER.post('v1/triple-a-admin/upload-docs', payload)
  },
  get_client_info(payload) {
    return API_ACCOUNT.get(`v1/account-service/clientInfo?client_id=${payload.clientId}`)
  },
  get_docs_list(payload) {
    return API_USER.post('v1/triple-a-admin/doc-info', payload)
  },
  sync_info() {
    return API_USER.post('v1/triple-a-admin/sync-info')
  },
  sync_user_info(payload) {
    return API_USER.post('v1/triple-a-admin/sync-individual-info', payload)
  },
  delete_user(payload) {
    return API_USER.post('v1/triple-a-admin/delete-user', payload)
  },
  delete_doc(payload) {
    return API_USER.post('v1/triple-a-admin/delete-document', payload)
  },
}