import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: '/',
        component: () => import('@/views/Dashboard/User.vue'),
      },
      {
        path: '/users',
        redirect: { route: '/home' }
      },
      {
        path: '/operators',
        component: () => import('@/views/Dashboard/Operator.vue'),
      },
    ],
  },
  {
    path: '/chatbot',
    name: 'Chatbot',
    component: () => import('@/views/Chatbot.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  let isAuthenticated = localStorage.getItem('isAuthenticated') || false
  if (
    to.name !== 'Login' &&
    to.name !== 'Set Password' &&
    to.name !== 'Reset Password' &&
    to.name !== 'Chatbot' &&
    !isAuthenticated
  ) {
    next({ name: 'Login' })
  } else if (to.name === 'Login' && isAuthenticated) {
    next({ route: '/users' })
  }
  else next()
})


export default router
