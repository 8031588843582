import authService from '@/services/auth.js'

const getDefaultState = () => {
  return {
    isAuthenticated: localStorage.getItem('isAuthenticated') || false,
    email: '',
    isAdmin: localStorage.getItem('isAdmin') || false,
    isSuperAdmin: localStorage.getItem('isSuperAdmin') || false,
  }
}

const state = getDefaultState()

const getters = {
  isSuperAdmin: (state) => state.isSuperAdmin
}
const mutations = {
  changeCookiePolicy(state, status) {
    state.acceptCookies = status
  },
  logout(state) {
    Object.assign(state, getDefaultState())
    state.isAuthenticated = false
    localStorage.clear()
  },
  auth_login(state, data) {
    state.email = data.data.email
    state.isAdmin = (data.data.isAdmin === 'true' || data.data.isAdmin === true)
    state.isSuperAdmin = (data.data.isSuperAdmin === 'true' || data.data.isSuperAdmin === true)
    localStorage.setItem('token', data.data.accessToken)
    localStorage.setItem('isAdmin', state.isAdmin)
    localStorage.setItem('isSuperAdmin', state.isSuperAdmin)
  },
  authenticate(state, data) {
    state.isAuthenticated = data
    localStorage.setItem('isAuthenticated', data)
  }
}
const actions = {
  setCookiePolicy({ commit }, status) {
    commit('changeCookiePolicy', status)
  },
  async login({ commit }, payload) {
    let response = await authService.login(payload)
    commit('auth_login', response)
    return response
  },
  async set_password(context, payload) {
    await authService.set_password(payload)
  },
  async forgot_userid(context, payload) {
    await authService.forgot_userid(payload)
  },
  async send_password_reset_request(context, payload) {
    await authService.send_password_reset_request(payload)
  },
  async verify_otp(context, payload) {
    await authService.verify_otp(payload)
  },
  async reset_password(context, payload) {
    return await authService.reset_password(payload)
  },
  async change_password(context, payload) {
    await authService.change_password(payload)
  },
  async reset_admin_password(context, payload) {
    return await authService.reset_admin_password(payload)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
