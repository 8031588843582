import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import store from './store'
import '@/assets/main.css'
import VueCookie from 'vue-cookie'


Vue.config.productionTip = false
Vue.prototype.$https = axios

Vue.use(VueCookie)
export const bus = new Vue()

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
