import userServices from '@/services/user.js'

const getDefaultState = () => {
  return {
    adminList: [],
    userList: [],
    clientInfo: {},
    userDocsList: []
  }
}

const state = getDefaultState()

const getters = {
  adminList: (state) => state.adminList,
  userList: (state) => state.userList,
  clientInfo: (state) => state.clientInfo,
  userDocsList: (state) => state.userDocsList
}

const actions = {
  async create_user(context, payload) {
    await userServices.create_user(payload)
  },
  async create_admin(context, payload) {
    await userServices.create_admin(payload)
  },
  async get_admin_list({ commit }) {
    commit('admin_list', await userServices.get_admin_list())
  },
  async get_user_list({ commit }) {
    commit('user_list', await userServices.get_user_list())
  },
  async edit_user(context, payload) {
    await userServices.edit_user(payload)
  },
  async edit_admin(context, payload) {
    await userServices.edit_admin(payload)
  },
  async activate_user(context, payload) {
    await userServices.activate_user(payload)
  },
  async deactivate_user(context, payload) {
    await userServices.deactivate_user(payload)
  },
  async activate_admin(context, payload) {
    await userServices.activate_admin(payload)
  },
  async deactivate_admin(context, payload) {
    await userServices.deactivate_admin(payload)
  },
  async upload_single_doc(context, payload) {
    return await userServices.upload_single_doc(payload)
  },
  async upload_docs(context, payload) {
    return await userServices.upload_docs(payload)
  },
  async get_client_info(context, payload) {
    return await userServices.get_client_info(payload)
  },
  async get_docs_list({ commit }, payload) {
    commit('docs_list', await userServices.get_docs_list(payload))
  },
  async delete_doc(context, payload) {
    await userServices.delete_doc(payload)
  },
  async sync_info() {
    return await userServices.sync_info()
  },
  async sync_user_info(context, payload) {
    return await userServices.sync_user_info(payload)
  },
  async delete_user(context, payload) {
    await userServices.delete_user(payload)
  },
}

const mutations = {
  admin_list(state, data) {
    state.adminList = data.data.adminInfo
    state.adminList.forEach((admin) => {
      admin.name = `${admin.first_name} ${admin.last_name}`
    })
  },
  user_list(state, data) {
    state.userList = data.data.userInfo
    state.userList.forEach((user) => {
      user.name = `${user.first_name} ${user.last_name}`
    })
  },
  client_info(state, data) {
    state.clientInfo = data
  },
  reset_user_store(state) {
    Object.assign(state, getDefaultState())
  },
  docs_list(state, data) {
    state.userDocsList = data.data.response
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
