import axios from 'axios'
import { bus } from '@/main'

const API = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})

API.interceptors.request.use((config) => {
  const token = localStorage.getItem('token', false)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

const API_USER = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_USER,
})

API_USER.interceptors.request.use((config) => {
  const token = localStorage.getItem('token', false)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

const API_ACCOUNT = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_ACCOUNT
})

API_ACCOUNT.interceptors.request.use((config) => {
  const token = localStorage.getItem('token', false)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})



API.interceptors.response.use(
  (response) => response,
  async function (error) {
    if (error.response.status === 422) {
      bus.$emit('sessionExpired')
    }
    return Promise.reject(error)
  }
)

API_USER.interceptors.response.use(
  (response) => response,
  async function (error) {
    if (error.response.status === 422) {
      bus.$emit('sessionExpired')
    }
    return Promise.reject(error)
  }
)

API_ACCOUNT.interceptors.response.use(
  (response) => response,
  async function (error) {
    if (error.response.status === 422) {
      bus.$emit('sessionExpired')
    }
    return Promise.reject(error)
  }
)

export { API, API_USER, API_ACCOUNT }
